@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

html,
body,
#root {
  width: 100%;
  height: 100%;
}

/*body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url("Images/sideprof.jpg");
  background-position: 0% 90%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}*/
body {
  margin: 0;
  overflow-y: scroll;
  
}

#root {
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

#splash-image {
  background-image: linear-gradient(0deg,rgb(0, 0, 0) 0%,rgba(0, 0, 0, .8) 10%,rgba(0, 0, 0, .4) 40%), url("Images/zoomout-vans-2.jpg");
  height: 800px;
  background-position: 0% 90%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  overflow-y: scroll;
}
